<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      layout="vertical"
      class="wizard-vertical"
      :hide-buttons="false"
      :start-index="0"
      @on-complete="enviaFormulario"
    >
      <div style="text-align: center">
        <!-- Temporário, ajuste destransformar a lista -->
        <h1>Colaborador: {{ Colaborador.nomefunc }}</h1>
        <h4>Cargo: {{ Colaborador.descrcargo }} - Admissão: {{ Colaborador.dtadm }}</h4>
      </div>
      <hr>
      <tab-content title="Normas Internas">
        <template>
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h3 class="mb-0">
              Normas Internas
            </h3>
            <h6 class="mb-0">
              BPF, código de ética e conduta, vestimenta, etc...
            </h6>
          </b-col>
          <b-col md="12">
            <b-form-group>

              <b-form-textarea
                v-model="formfacrh1"
                name="formfacrh1"
                placeholder="Informe aqui o que foi tratado com o colaborador..."
              />
            </b-form-group>
          </b-col>
          </b-row>
        </template>
      </tab-content>

      <tab-content title="Produtividade">
        <template>
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h3 class="mb-0">
                Produtividade
              </h3>
              <h6 class="mb-0">
                Administração do tempo, planejamento e trabalho sob pressão
              </h6>
            </b-col>
            <b-col md="12">
            <b-form-group>

              <b-form-textarea
                v-model="formfacrh2"
                name="formfacrh2"
                placeholder="Informe aqui o que foi tratado com o colaborador..."
              />
            </b-form-group>
          </b-col>
          </b-row>
        </template>
      </tab-content>

      <tab-content title="Conhecimento">
        <template>
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h3 class="mb-0">
                Conhecimento do Trabalho
              </h3>
              <h6 class="mb-0">
                Assertividade, habilidades.
              </h6>
            </b-col>
            <b-col md="12">
            <b-form-group>

              <b-form-textarea
                v-model="formfacrh3"
                name="formfacrh3"
                placeholder="Informe aqui o que foi tratado com o colaborador..."
              />
            </b-form-group>
          </b-col>
          </b-row>
        </template>
      </tab-content>

      <tab-content title="Segurança">
        <template>
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h3 class="mb-0">
                Segurança do Trabalho
              </h3>
              <h6 class="mb-0">
                EPI's, atitudes, cumprimento de normas.
              </h6>
            </b-col>
            <b-col md="12">
            <b-form-group>

              <b-form-textarea
                v-model="formfacrh4"
                name="formfacrh4"
                placeholder="Informe aqui o que foi tratado com o colaborador..."
              />
            </b-form-group>
          </b-col>
          </b-row>
        </template>
      </tab-content>

      <tab-content title="Assiduidade">
        <template>
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h3 class="mb-0">
                Assiduidade
              </h3>
              <h6 class="mb-0">
                Faltas, atrasos, saídas antecipadas, atestados e declaração.
              </h6>
            </b-col>
            <b-col md="12">
            <b-form-group>

              <b-form-textarea
                v-model="formfacrh5"
                name="formfacrh5"
                placeholder="Informe aqui o que foi tratado com o colaborador..."
              />
            </b-form-group>
          </b-col>
          </b-row>
        </template>
      </tab-content>

        <tab-content title="Inicitativa e Cooperação">
          <template>
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h3 class="mb-0">
                  Inicitativa e Cooperação
                </h3>
                <h6 class="mb-0">
                  Foco no resultado e na solução e proatividade.
                </h6>
              </b-col>
              <b-col md="12">
              <b-form-group>

                <b-form-textarea
                v-model="formfacrh6"
                name="formfacrh6"
                placeholder="Informe aqui o que foi tratado com o colaborador..."
                />
              </b-form-group>
            </b-col>
            </b-row>
          </template>
        </tab-content>

        <tab-content title="Motivação">
          <template>
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h3 class="mb-0">
                  Motivação
                </h3>
                <h6 class="mb-0">
                  Otimismo, entusiasmo e enfluencia positiva.
                </h6>
              </b-col>
            <b-col md="12">
              <b-form-group>

                <b-form-textarea
                v-model="formfacrh7"
                name="formfacrh7"
                placeholder="Informe aqui o que foi tratado com o colaborador..."
                />
              </b-form-group>
            </b-col>
            </b-row>
          </template>
        </tab-content>

        <tab-content title="Relacionamento">
          <template>
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h3 class="mb-0">
                  Relacionamento com colegas
                </h3>
                <h6 class="mb-0">
                  Trabalho em equipe, simpatia e empatia.
                </h6>
              </b-col>
            <b-col md="12">
              <b-form-group>

                <b-form-textarea
                v-model="formfacrh8"
                name="formfacrh8"
                placeholder="Informe aqui o que foi tratado com o colaborador..."
                />
              </b-form-group>
            </b-col>
            </b-row>
          </template>
        </tab-content>

        <tab-content title="Comprometimento">
          <template>
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h3 class="mb-0">
                  Comprometimento com Trabalho
                </h3>
                <h6 class="mb-0">
                  Planejamento, organização, senso de dono, utilização dos recursos da empresa.
                </h6>
              </b-col>
            <b-col md="12">
              <b-form-group>

                <b-form-textarea
                v-model="formfacrh9"
                name="formfacrh9"
                placeholder="Informe aqui o que foi tratado com o colaborador..."
                />
              </b-form-group>
            </b-col>
            </b-row>
          </template>
        </tab-content>

        <tab-content title="Auto-Desenvolvimento">
          <template>
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h3 class="mb-0">
                  Auto-Desenvolvimento
                </h3>
                <h6 class="mb-0">
                  Flexibilidade, Visão sistêmica.
                </h6>
              </b-col>
            <b-col md="12">
              <b-form-group>

                <b-form-textarea
                v-model="formfacrh10"
                name="formfacrh10"
                placeholder="Informe aqui o que foi tratado com o colaborador..."
                />
              </b-form-group>
            </b-col>
            </b-row>
          </template>
        </tab-content>
      </form-wizard>
  </div>
</template>

<script>

import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
} from 'bootstrap-vue'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
  },
  data() {
    return {
      Colaborador: {
        codemp: 0,
        codcencus: 0,
        codfunc: 0,
        nomefunc: '',
        descrcargo: '',
        dtadm: '',
      },
      notification: {
      },
      respostaFac: {
        codemp: 0,
        codcencus: 0,
        codfunc: 0,
        listaFormList: [],
      },
    }
  },
  mounted() {
    if (this.$route.params.codfunc != null) {
      this.MostraInformacoesColaborador(this.$route.params.codemp, this.$route.params.codfunc)
    } else {
      // this.PermiteSalvar = true
    }
  },
  methods: {
    async MostraInformacoesColaborador($CODEMP, $CODFUNC) {
      const CODEMP = $CODEMP
      const CODFUNC = $CODFUNC
      const URLpost = `/skFormFacRH/MostraFuncionario?CODEMP=${CODEMP}&CODFUNC=${CODFUNC}`
      await apiPortalSky.post(URLpost).then(response => {
        if (response.status === 200) {
          this.Colaborador = response.data
        }
      }).catch(error => {
        this.notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(this.notification)
      })
    },
    async enviaFormulario() {
      let notification = ''
      let MsgErro = ''
      const usuario = this.$session.get('usuario')
      const URLpost = `/skFormFacRH/EnvioFacRH?CODUSU=${usuario.codusu}`
      this.CarregaDadosNoObjeto()
      const ParametroJson = JSON.parse(JSON.stringify(this.respostaFac))
      await apiPortalSky.post(URLpost, ParametroJson, {
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(response => {
        if (response.status === 200) {
          MsgErro = `Fac para ${this.Colaborador.nomefunc} incluído com Sucesso`
          notification = { message: MsgErro, alert_type: 'success' }
          this.$session.set('notification', notification)
          this.$router.push('/formularios/rh/FacSubordinados')
        }
      })
        .catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
        })
    },
    CarregaDadosNoObjeto() {
      this.respostaFac.codemp = this.Colaborador.codemp
      this.respostaFac.codcencus = this.Colaborador.codcencus
      this.respostaFac.codfunc = this.Colaborador.codfunc

      let formulario = {
        formquestao: 1,
        formresposta: this.formfacrh1,
      }
      this.respostaFac.listaFormList[0] = formulario
      formulario = {
        formquestao: 2,
        formresposta: this.formfacrh2,
      }
      this.respostaFac.listaFormList[1] = formulario
      formulario = {
        formquestao: 3,
        formresposta: this.formfacrh3,
      }
      this.respostaFac.listaFormList[2] = formulario
      formulario = {
        formquestao: 4,
        formresposta: this.formfacrh4,
      }
      this.respostaFac.listaFormList[3] = formulario
      formulario = {
        formquestao: 5,
        formresposta: this.formfacrh5,
      }
      this.respostaFac.listaFormList[4] = formulario
      formulario = {
        formquestao: 6,
        formresposta: this.formfacrh6,
      }
      this.respostaFac.listaFormList[5] = formulario
      formulario = {
        formquestao: 7,
        formresposta: this.formfacrh7,
      }
      this.respostaFac.listaFormList[6] = formulario
      formulario = {
        formquestao: 8,
        formresposta: this.formfacrh8,
      }
      this.respostaFac.listaFormList[7] = formulario
      formulario = {
        formquestao: 9,
        formresposta: this.formfacrh9,
      }
      this.respostaFac.listaFormList[8] = formulario
      formulario = {
        formquestao: 10,
        formresposta: this.formfacrh10,
      }
      this.respostaFac.listaFormList[9] = formulario
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
